import React from "react"
import './LayoutTemplate.css'
import './divi.css'
import './bootstrap.min.css'
import './new.css'
import { StickyContainer, Sticky } from 'react-sticky';
import { Link } from "react-scroll";
import { Nav, Navbar } from 'react-bootstrap';
import Superlink from 'gatsby-plugin-superlink';

export default ({ children }) => {
	const theurl = typeof window !== 'undefined' ? window.location.href : '';

	if (
		theurl.includes('history') ||
		theurl.includes('news') ||
		theurl.includes('survey') || 
		theurl.includes('happenings') || 
		theurl.includes('boos') || 
		theurl.includes('recreation') || 
		theurl.includes('recreation-2') || 
		theurl.includes('recreation-success') ||
		theurl.includes('recreation2-success')
	) { 

		return (
			<div>
				<section className="section">
					<div className="oxford-head">
						<div className="container text-right">
							<div className="row header-row">
								<div className="col-12 d-flex d-md-block justify-content-between justify-content-md-end">
									<a rel="noreferrer" href="https://www.oxfordproperties.com/" target="_blank"><img aria-label="Oxford Properties Logo" className="img-fluid w-100 mr-2" src="https://62a66fa6-cdn.agilitycms.cloud/global/oxford-logo.png" alt="Oxford Properties Logo" title="Oxford Properties Logo" height="auto" width="auto" /></a>
									<a rel="noreferrer" href="https://www.papent.com/" target="_blank"><img aria-label="Pappas Enterprises Logo" className="img-fluid w-100" src="https://62a66fa6-cdn.agilitycms.cloud/global/pappas-ent-logo.png" alt="Pappas Enterprises Logo" title="Pappas Enterprises Logo" height="auto" width="auto" /></a>
								</div>
							</div>
						</div>
					</div>
					<StickyContainer>
						<Sticky>
						{({
							style,
							isSticky,
							calculatedHeight
						}) => (
						<div className="stickyWrap">
							<Navbar collapseOnSelect expand="lg" style={{...style, background: isSticky ? '#56865f' : 'none'}}  className={(isSticky ? 'navbar-dark is-sticky' : 'navbar-dark not-sticky')}>
								<div className={(isSticky ? 'container align-items-center' : 'container align-items-start')}>
									<a href="https://pappasway.com/" className="navbar-brand">
										<img src="https://62a66fa6-cdn.agilitycms.cloud/global/oxford-logo.png" alt="Pappas Way" />
									</a>

									<Navbar.Toggle aria-controls="responsive-navbar-nav" />

									<Navbar.Collapse>
										<Nav className="ml-auto mt-2 mt-lg-0 py-3 text-right">
											<Nav.Item>
												<Nav.Link eventKey="1" as={Superlink} to="/#jump-oursite">OUR SITE</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="2" as={Superlink} to="/#jump-history">HISTORY</Nav.Link>
											</Nav.Item>						    	
											<Nav.Item>
												<Nav.Link eventKey="3" as={Superlink} to="/#jump-neighborhood">NEIGHBORHOOD</Nav.Link>
											</Nav.Item>						    	
											{/* <Nav.Item>
												<Nav.Link eventKey="4" as={Superlink} to="/survey">SURVEY</Nav.Link>
											</Nav.Item> */}
											<Nav.Item>
												<Nav.Link eventKey="5" as={Superlink} to="/happenings">HAPPENINGS</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="6" as={Superlink} to="/#jump-about">ABOUT</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="7" as={Superlink} to="/#jump-news">NEWS</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="8" as={Superlink} to="/#jump-contact">CONTACT</Nav.Link>
											</Nav.Item>
										</Nav>
									</Navbar.Collapse>
								</div>
							</Navbar>
						</div>
						)}
						</Sticky>
						{children}
					</StickyContainer>
				</section>
			</div>
		)
	} 
	
	else {
		return (
			<div>
				<section className="section">
					<div id="tophead" className="oxford-head">
						<div className="container text-right">
							<div className="row header-row">
								<div className="col-12 d-flex d-md-block justify-content-between justify-content-md-end">
										<a rel="noreferrer" href="https://www.oxfordproperties.com/" target="_blank"><img aria-label="Oxford Properties Logo" className="img-fluid w-100 mr-2" src="https://62a66fa6-cdn.agilitycms.cloud/global/oxford-logo.png" alt="Oxford Properties Logo" title="Oxford Properties Logo" height="auto" width="auto" />
										</a>
										<a rel="noreferrer" href="https://www.papent.com/" target="_blank"><img aria-label="Pappas Enterprises Logo" className="img-fluid w-100" src="https://62a66fa6-cdn.agilitycms.cloud/global/pappas-ent-logo.png" alt="Pappas Enterprises Logo" title="Pappas Enterprises Logo" height="auto" width="auto" />
										</a>
								</div>
							</div>
						</div>
					</div>
					<StickyContainer>
						<Sticky>
							{({
							style,
							isSticky,
							calculatedHeight
							}) => (
							<div className="stickyWrap">
								<Navbar collapseOnSelect 
									expand="lg" style={{...style, background: isSticky ? '#56865f' : 'none'}}  
									className={(isSticky ? 'navbar-dark is-sticky' : 'navbar-dark not-sticky')}
								>
									<div className={(isSticky ? 'container align-items-center' : 'container align-items-start')}>
										<Link to="oxford-head" smooth={true} duration={500} className="navbar-brand">
											<img src="https://isenbergprojects.com/wp-content/uploads/pappas-arial.png" alt="Pappas Way" />
										</Link>

										<Navbar.Toggle aria-controls="responsive-navbar-nav" />

										<Navbar.Collapse>
											<Nav className="ml-auto mt-2 mt-lg-0 py-3 text-right">
												<Nav.Item>
													<Nav.Link eventKey="1" as={Link} to="block-our-site" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>OUR SITE</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="2" as={Link} to="block-history" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>HISTORY</Nav.Link> </Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="3" as={Link} to="block-neighborhood" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>NEIGHBORHOOD</Nav.Link>
												</Nav.Item>					    	
												{/* <Nav.Item>
													<Nav.Link eventKey="4" as={Superlink} to="/survey">SURVEY</Nav.Link>
												</Nav.Item> */}
												<Nav.Item>
													<Nav.Link eventKey="5" as={Superlink} to="/happenings">HAPPENINGS</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="6" as={Link} to="block-about" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>ABOUT</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="7" as={Link} to="block-news" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>NEWS</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="8" as={Link} to="block-contact" spy={true} activeClass="globalnav-active" smooth={true} duration={500} offset={-70}>CONTACT</Nav.Link>
												</Nav.Item>
											</Nav>
										</Navbar.Collapse>
									</div>
								</Navbar>
						</div>
						)}
						</Sticky>
						{children}
					</StickyContainer>
				</section>
			</div>
		)
	}
}

