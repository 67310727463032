import React from 'react';
import { renderHTML } from '../agility/utils'

const HistoryBlock = ({ item }) => {
	return (
		<div className="content-block content-block-green content-subheader block-history" id="jump-history" name="block-history">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-6 order-2 order-lg-first">
						<img className="img-fluid" src={item.customFields.image.url} alt={item.customFields.image.label} />
					</div>
					<div className="col-12 col-lg-6 order-1 order-lg-last">
						<h2 className="header-blocked">{item.customFields.title}</h2>
						<div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
					</div>
				</div>
					<div className="row mt-5" dangerouslySetInnerHTML={renderHTML(item.customFields.timeline)}></div>
			</div>
		</div>

	);
}
export default HistoryBlock;

