import React from 'react';
import ContentZone from '../agility/components/ContentZone'
import '../components/subpage.css'

const SubPageTemplate = (props) => {
    return (
        <div className="subpage one-column-template">
            <ContentZone name="MainContentZone" {...props} />
        </div>
    );
}
export default SubPageTemplate;
