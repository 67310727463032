import React from "react"

const AutoModal = ({ item }) => {

    return (
		<div>
        </div>
    );
}

export default AutoModal;
