import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        class="footer-form"
        action="https://pappasway.us7.list-manage.com/subscribe/post?u=1f1d6930ed56991ebab87e788&amp;id=6d1bd6c531"
        method="POST"
        target="_blank"
        noValidate=""
      >
        <div className="form-row">
          <p><strong><u><a href="mailto:community@pappasway.com" className="link-white">Reach us with questions</a></u></strong></p>
        </div>
        <div className="form-row">
          <p><strong>Or subscribe to hear more about Pappas Way programs</strong></p>
        </div>
          {/* <!-- Begin Mailchimp Signup Form --> */}
        <div className="form-row">
          <div id="mc_embed_signup">
          <div class="mc-field-group"><p className="form-text">EMAIL ADDRESS <span className="asterisk text-white">*</span> <input type="email" defaultValue="" name="EMAIL" className="form-email" id="mce-EMAIL" /></p></div>
         </div>
          {/* <!-- End Mailchimp Signup Form --> */}
        </div>
        <div className="g-recaptcha" data-sitekey="6Lfy4d0aAAAAACUq_0lfC6GPQgWOJ71azGHneu9F"></div>
        <input type="text" name="_gotcha" className="d-none" />
        {status === "SUCCESS" ? <p>Thank you for reaching out. We look forward to being in touch as soon as possible.</p> : <button className="btn btn-ghost btn-lg">Submit</button>}
        {status === "ERROR" && <p>Oops! There was an error.</p>}        
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
