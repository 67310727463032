import React from 'react';
import Superlink from 'gatsby-plugin-superlink';

const SubPageFooter = ({ item }) => {
	return (


		<div className="content-block content-block-dark content-subheader block-footer subfooter" name="block-subfooter">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-7 mt-4 mt-lg-0">
					<img src="https://62a66fa6-cdn.agilitycms.cloud/Attachments/NewItems/pappas-arial_20201127185646_0.png" alt="Pappas Way" className="img-fluid logo-pappas my-4" />
					<a rel="noopener noreferrer" href="https://www.oxfordproperties.com/" target="_blank">
						<img aria-label="Oxford Properties Logo" className="img-fluid logo-oxford mr-2" src="https://62a66fa6-cdn.agilitycms.cloud/global/oxford-logo.png" alt="Oxford Properties Logo" title="Oxford Properties Logo" />
					</a>
					<a rel="noopener noreferrer" href="https://www.papent.com/" target="_blank">
						<img aria-label="Pappas Enterprises Logo" className="img-fluid logo-oxford" src="https://62a66fa6-cdn.agilitycms.cloud/global/pappas-ent-logo.png" alt="Pappas Enterprises Logo" title="Pappas Enterprises Logo" />
					</a>
					</div>
					<div className="col-12 col-lg-3 mt-5 mt-lg-0">
					<ul className="mt-3">
						<li><Superlink to="/#jump-oursite">OUR SITE</Superlink></li>
						<li>HISTORY</li>
						<li><Superlink to="/#jump-neighborhood">NEIGHBORHOOD</Superlink></li>
						<li><Superlink to="/survey">SURVEY</Superlink></li>
						<li><Superlink to="/#jump-programs">HAPPENINGS</Superlink></li>
						<li><Superlink to="/#jump-about">ABOUT</Superlink></li>
						<li><Superlink to="/#jump-news">NEWS</Superlink></li>
						<li><Superlink to="/#jump-contact">CONTACT</Superlink></li>
					</ul>
					</div>
					<div className="col-12 col-lg-2 pb-2 d-flex align-items-end justify-content-end">
						<a className="d-block float-right social-fb" href="https://www.facebook.com/profile.php?id=100072378399356" target="_blank" rel="noreferrer">
							<img className="img-fluid" src="https://62a66fa6-cdn.agilitycms.cloud/global/facebook-circular-logo.png" alt="Facebook Logo" />
						</a>

					</div>
				</div>
			</div>
		</div>

		
	);
}
export default SubPageFooter;

