import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import { renderHTML} from '../agility/utils'
import './PostListing.css'
import Img from 'gatsby-image'

export default (props) => (
	<StaticQuery
		query={graphql`
        query ActivationListingModuleQuery {
            allAgilityActivation(
                sort: {fields: properties___itemOrder, order: ASC}, 
                filter: {
                    properties: {
                     referenceName: {
                      eq: "activations"}
                    },
                    customFields: {
                        homepageFeature: {
                            eq: "true"
                        }
                    }
                },
                limit: 10
                ) {
                totalCount
                nodes {
                    contentID
                    customFields {
                        title
                        details
                        tag
                        linkText
                        linkTo
                        imageLocalImg {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                        }
                    }
                    properties {
                        referenceName
                    }
                }
            }
          }
        `}
		render={queryData => {
			return (
				<PostsListing activations={queryData.allAgilityActivation.nodes} {...props} />
			);
		}}
	/>
)

const PostsListing = ({ item, activations }) => {
    return (
        <div className="content-block content-block-green content-subheader block-activations" id="jump-programs">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="header-blocked">{item.customFields.title}</h2>
                        <div className="row">
                            <Posts activations={activations} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Posts = ({ activations }) => {
    return activations.map(activation => {
        return <Post key={activation.contentID} activation={activation} />;
    })
}

const Post = ({ activation }) => {

    return(
        <div className="col-12 col-lg-6 mt-4" key={activation.contentID}>
            <div className="card h-100">
            {activation.customFields.tag &&
                <span className="activationTag">{activation.customFields.tag}</span>
            }
              <PostImage className="card-img-top img-fluid" image={activation.customFields.imageLocalImg} label={activation.customFields.image ? activation.customFields.image.label : `Post Image`} />
              <div className="card-body">
                <h3 className="card-title">{activation.customFields.title}</h3>
                <div dangerouslySetInnerHTML={renderHTML(activation.customFields.details)}></div>
                {activation.customFields.linkText &&
                    <a className="btn btn-ghost card-link" href={activation.customFields.linkTo} target="_blank" rel="noreferrer">{activation.customFields.linkText}</a>
                }
              </div>
            </div>
        </div>
    )
}

const PostImage = ({ image, label }) => {
    let imageToRender = null;
    
    if(image && image.childImageSharp) {

        imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} /> 
    }
    return imageToRender;
}
