import React from 'react';

const InfoBar = ({ item }) => {
	return (

		<div className="content-block content-block-dark content-subheader block-footer block-infobar" id="jump-infobar" name="block-infobar">
			<div className="container">
				<div className="flex-row align-items-center justify-content-between text-center">
					<div>
						<h2 className="infobar-header">{item.customFields.title}</h2>
					</div>
					<div className="mt-4">
						<a className="infobarlink" href={item.customFields.linkDestination}>{item.customFields.linkText}</a>
					</div>
				</div>
			</div>
		</div>
		
	);
}
export default InfoBar;

