import React from 'react';
import { renderHTML } from '../agility/utils'

const PrettyHeader = ({ item }) => {
	const isHex = {};
	if (item.customFields.backgroundHex) {
		isHex.backgroundColor = "#" + item.customFields.backgroundHex + ""
	}
	return (

		<div className="prettyheader" style={isHex}>
			<div className="container">
				<div className="row">
                    <div className="col-9 mx-auto text-center">
				    	<img className="img-fluid w-100 mb-3" src={item.customFields.logo.url} alt="" />
						<h2>{item.customFields.title}</h2>

						<h2>{item.customFields.subtitle}</h2>
						<div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">


				<div class="card-columns mt-5">
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs1.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs1.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs2.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs2.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs3.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs3.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs4.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs4.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs5.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs5.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs6.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs6.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs7.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs7.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs8.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs8.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs9.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs9.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs10.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs10.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs11.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs11.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs12.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs12.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs13.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs13.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs14.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs14.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs15.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs15.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs16.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs16.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs17.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs17.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs18.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs18.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs19.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs19.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs20.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs20.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs21.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs21.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs22.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs22.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs23.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs23.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs24.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs24.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs25.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs25.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs26.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs26.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs27.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs27.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs28.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs28.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs29.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs29.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs30.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs30.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs31.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs31.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs32.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs32.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs33.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs33.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs34.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs34.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs35.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs35.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs36.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs36.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs37.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs37.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs38.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs38.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs39.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs39.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs40.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs40.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs41.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs41.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs42.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs42.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs43.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs43.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs44.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs44.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs45.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs45.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs46.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs46.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs47.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs47.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs48.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs48.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs49.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs49.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs50.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs50.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs51.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs51.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs52.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs52.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs53.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs53.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs54.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs54.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs55.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs55.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs56.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs56.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs57.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs57.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs58.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs58.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs59.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs59.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs60.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs60.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs61.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs61.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs62.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs62.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs63.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs63.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs64.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs64.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs65.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs65.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs66.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs66.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs67.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs67.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs68.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs68.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs69.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs69.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs70.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs70.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs71.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs71.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs72.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs72.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs73.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs73.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs74.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs74.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs75.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs75.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs76.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs76.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs77.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs77.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs78.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs78.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs79.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs79.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs80.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs80.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs81.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs81.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs82.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs82.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs83.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs83.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs84.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs84.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs85.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs85.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs86.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs86.jpg" alt="" />
						</a>
					</div>
					<div class="card">
						<a href="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs87.jpg" target="_blank" rel="noreferrer">
						<img class="card-img" src="https://62a66fa6-cdn.agilitycms.cloud/MediaGroupings/1/PappasDogs87.jpg" alt="" />
						</a>
					</div>
				</div>


				</div>
			</div>
				    <img className="img-fluid w-100" src={item.customFields.bottomImage.url} alt="" />
			
		</div>
		
	);
}
export default PrettyHeader;

