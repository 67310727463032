import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default (props) => (
    <StaticQuery
        query={graphql`
        query NewsLinkListingModuleQuery {
            allAgilityNewsLink(
                sort: {fields: properties___itemOrder, order: ASC}, 
                filter: {properties: { referenceName: { eq: "newslinks"}}},
                limit: 100
                ) {
                totalCount
                nodes {
                    contentID
                    customFields {
                        title
                        publisher
                        linkto
                        imageLocalImg {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                        }
                    }
                    properties {
                        referenceName
                    }
                }
            }
          }
        `}
        render={queryData => {
            return (
                <PostsListing newslinks={queryData.allAgilityNewsLink.nodes} {...props} />
            );
        }}
    />
)

const PostsListing = ({ item, newslinks }) => {
    return (
        <div className="content-block content-block-green content-subheader content-news block-news" id="jump-news">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="header-blocked">{item.customFields.title}</h2>
                        <div className="row">
                            <Posts newslinks={newslinks} />

                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

const Posts = ({ newslinks }) => {
    return newslinks.map(newslink => {
        return <Post key={newslink.contentID} newslink={newslink} />;
    })
}

const Post = ({ newslink }) => {
    
    return(
        <div className="col-12 col-lg-4 mt-4" key={newslink.contentID}>
            <div className="card h-100">
              <PostImage className="card-img-top img-fluid" image={newslink.customFields.imageLocalImg} label={newslink.customFields.image ? newslink.customFields.image.label : `Post Image`} />
              <div className="card-body">
                <h3 className="card-title">{newslink.customFields.title}</h3>
                <span className="card-tag">{newslink.customFields.publisher}</span>
                <a className="btn btn-ghost" href={newslink.customFields.linkto} role="button" rel="noreferrer" target="_blank">Read More</a>
              </div>
            </div>
        </div>
    )
}

const PostImage = ({ image, label }) => {
    let imageToRender = null;
    
    if(image && image.childImageSharp) {

        imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} /> 
    }
    return imageToRender;
}

