import React from 'react';
import { renderHTML } from '../agility/utils'

const TitledSection = ({ item }) => {
	return (

        <div className="content-block content-block-white content-subheader block-activations recgallery" id="events">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="header-blocked">{item.customFields.title}</h2>
                            <div className="row" dangerouslySetInnerHTML={renderHTML(item.customFields.theContent)}></div>
                    </div>
                </div>
            </div>
        </div>
		
	);
}
export default TitledSection;

