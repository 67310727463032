import React from 'react';
import { renderHTML } from '../agility/utils'

const RecStationExplainer = ({ item }) => {
	return (
		 
	    <div className="recfeatures">
	        <div className="container">
	            <div className="row">
	                <div className="col-12 text-center">
	                    <h2>HOW IT WORKS</h2>    
	                </div>
	            </div>
	            <div dangerouslySetInnerHTML={renderHTML(item.customFields.theContent)}></div>
	        </div>
	    </div>



	);
}
export default RecStationExplainer;

