import React from 'react';
import { renderHTML } from '../agility/utils'

const RichTextAreaFull = ({ item }) => {

    return (
        <section className="mb-0">
            <div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
        </section>
    );
}

export default RichTextAreaFull;
