import React from 'react'
import { Helmet } from "react-helmet"

const Termly = ({ title, description }) => {
    return (
        <Helmet>
            <script type="text/javascript" src="https://app.termly.io/embed.min.js" data-auto-block="on" data-website-uuid="5f286528-3e22-4d4e-9fd9-e5d42889d57b" ></script>
        </Helmet>
    )
}

export default Termly;