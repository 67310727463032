import React from 'react';
import ContentZone from '../agility/components/ContentZone'

const OneColumnTemplate = (props) => {

	const theurl = typeof window !== 'undefined' ? window.location.href : '';
      const isRecSuccess = theurl.includes('recreation-success') || theurl.includes('recreation2-success');
	if (theurl.includes('recreation') && !isRecSuccess) { 
	return (
        <div className="one-column-template rectest">
            <ContentZone name="MainContentZone" {...props} />
        </div>
		)
	} else {
    return (
        <div className="one-column-template">
            <ContentZone name="MainContentZone" {...props} />
        </div>
    );
	}
}
export default OneColumnTemplate;
