import React from 'react';
import { renderHTML } from '../agility/utils'

const BackgroundContentBlock = ({ item }) => {
	let BlockStyle = {};
	if (item.customFields.backgroundImage) {
		BlockStyle.backgroundImage = "url('" + item.customFields.backgroundImage.url + "')";
	}
	return (

		<div className="content-block content-block-image block-subscribe content-subheader" style={BlockStyle}>
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-8 order-1">
						<h2 className="header-blocked background-subheader">{item.customFields.title}</h2>
						<div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
					</div>
				</div>
			</div>
		</div>
		
	);
}
export default BackgroundContentBlock;

