import React from 'react';
import { renderHTML } from '../agility/utils'

const RecStationSignup = ({ item }) => {
	return (
		 
            <div dangerouslySetInnerHTML={renderHTML(item.customFields.theContent)} class="recsignup" id="jumpRec">
            </div>


	);
}
export default RecStationSignup;

