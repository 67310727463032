import React from 'react';
import './SubPageHeader.css'

const SubPageHeader = ({ item }) => {
	let jumboTronStyle = {};
	if (item.customFields.backgroundImage) {
		jumboTronStyle.backgroundImage = "url('" + item.customFields.backgroundImage.url + "')";
	}
	return (
		<div className="oxford-jumbo subhead" style={jumboTronStyle}>
			<div className="container">
				<div className="row">
					<div className="col-10 col-md-6 custom-spacing">
						<h1 className="mb-0">{item.customFields.title}</h1>
					</div>
				</div>
			</div>
		</div>

		
	);
}
export default SubPageHeader;

