import React from 'react';
import { renderHTML } from '../agility/utils'

const RecStationHeader = ({ item }) => {
	return (
        <div className="recheader">
                        <img alt="" src="https://62a66fa6-cdn.agilitycms.cloud/global/rec-left-a.png" className="recleft" />
                        <img alt="" src="https://62a66fa6-cdn.agilitycms.cloud/global/rec-right-b.png" className="recright" />
            <div className="container">
                <div className="row">
                    <div className="col-9 mx-auto text-center">
                        <div class="reclogocontainer">
                        <img alt="" src="https://62a66fa6-cdn.agilitycms.cloud/global/RecLogoTransparent-2.png" className="img-fluid" />
                        </div>
                        <div dangerouslySetInnerHTML={renderHTML(item.customFields.theContent)}></div>
                        
                    </div>
                </div>
            </div>
        </div>
		
	);
}
export default RecStationHeader;

