import React from 'react';
import { renderHTML } from '../agility/utils'
import ContactForm from '../components/ContactForm'

const ContactBlock = ({ item }) => {
	return (

		<div className="content-block content-block-dark content-subheader block-footer" id="jump-contact" name="block-contact">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-5 mt-4 mt-lg-0">
						<h2 className="header-blocked">{item.customFields.title}</h2>
						<div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
					</div>
					<div className="col-12 col-lg-7 mt-3 mt-lg-0">
						<ContactForm/>
						<a className="d-block float-right social-fb" href="https://www.facebook.com/profile.php?id=100072378399356" target="_blank" rel="noreferrer">
							<img className="img-fluid" src="https://62a66fa6-cdn.agilitycms.cloud/global/facebook-circular-logo.png" alt="Facebook Logo" />
						</a>
					</div>
				</div>
			</div>
		</div>
		
	);
}
export default ContactBlock;

