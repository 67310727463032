import React from 'react';
import { renderHTML } from '../agility/utils'

const ContentBlock = ({ item }) => {
	let Position = {};
	if (item.customFields.imagePosition === "left") {
		Position.imagePositionFirst = "col-12 col-lg-6 order-1 order-lg-last";
		Position.imagePositionSecond = "col-12 col-lg-6 order-2 order-lg-first";
	} else {
		Position.imagePositionFirst = "col-12 col-lg-6 order-1";
		Position.imagePositionSecond = "col-12 col-lg-6 order-2";
	}
	let CustomName = {};
		if (item.customFields.name) {
			CustomName.name = item.customFields.name;
			CustomName.id = item.customFields.id;
		}
	return (

		<div className="content-block content-subheader" id={CustomName.id} name={CustomName.name}>
			<div className="container">
				<div className="row">
					<div className={Position.imagePositionFirst}>
						<h2 className="header-blocked">{item.customFields.title}</h2>
						<div dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></div>
					</div>
					<div className={Position.imagePositionSecond}>
						<img className="img-fluid mb-5 mb-lg-0" style={{"height" : "100%"}} src={item.customFields.image.url} alt={item.customFields.image.label} />
					</div>
				</div>
			</div>
		</div>
		
	);
}
export default ContentBlock;

