import React from 'react';
import { renderHTML } from '../agility/utils'

const SubheaderBlock = ({ item }) => {
	return (
		<div className="content-block content-subheader" id="jump-oursite" name="block-our-site">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 col-lg-5">
						<h2 className="text-left text-lg-right pr-5 pl-0 pl-xl-3 mr-0 mr-lg-3 py-3 mb-0">{item.customFields.title}</h2>
					</div>
					<div className="col-12 col-lg-7">
						<span dangerouslySetInnerHTML={renderHTML(item.customFields.body)}></span>
					</div>
				</div>

				<div className="row align-items-center mt-5">
					<div className="col-12 col-lg-4 mb-3 mb-lg-0">
						<img className="img-fluid" src={item.customFields.image1.url} alt="" />
					</div>
					<div className="col-12 col-lg-4 mb-3 mb-lg-0">
						<img className="img-fluid" src={item.customFields.image2.url} alt="" />
					</div>
					<div className="col-12 col-lg-4">
						<img className="img-fluid" src={item.customFields.image3.url} alt="" />
					</div>
				</div>
				
			</div>
		</div>


	);
}
export default SubheaderBlock;

